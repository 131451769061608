import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorKalina as author } from 'data/authors';
import styled from 'styled-components';

import img from 'img/blog/user-research/user-segmentation-hero.png';
import img1 from 'img/blog/user-research/user-segmentation.png';
import img2 from 'img/blog/user-research/segmentation-user-research.png';
import img3 from 'img/blog/user-research/research-url.png';
import img4 from 'img/blog/user-research/user-research-url.png';
import img5 from 'img/blog/user-research/user-research-url-1.png';
import img6 from 'img/blog/user-research/user-research-url-2.png';
import img7 from 'img/blog/user-research/user-research-button.png';
import img8 from 'img/blog/user-research/user-research-click.png';
import img9 from 'img/blog/user-research/user-research-number.png';
import img10 from 'img/blog/user-research/user-research-number-2.png';
import img11 from 'img/blog/user-research/user-research-number-3.png';
import img12 from 'img/blog/user-research/based-segmentation.png';
import img13 from 'img/blog/user-research/based-segmentation-2.png';
import img14 from 'img/blog/user-research/based-segmentation-3.png';
import img15 from 'img/blog/user-research/based-segmentation-4.png';
import img16 from 'img/blog/user-research/based-segmentation-5.png';
import img17 from 'img/blog/user-research/segmentation-search.png';
import img18 from 'img/blog/user-research/segmentation-search-2.png';
import img19 from 'img/blog/user-research/segmentation-location.png';
import img20 from 'img/blog/user-research/segmentation-location-2.png';
import img21 from 'img/blog/user-research/segmentation-location-3.png';
import img22 from 'img/blog/user-research/identify-users.png';
import img23 from 'img/blog/user-research/identify-users-2.png';
import img24 from 'img/blog/user-research/main-takeaways.png';
import img25 from 'img/blog/user-research/main-takeaways-2.png';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h4 {
    color: #777;
    font-weight: 700;
    font-size: 1rem;
  }
`;

const Content = () => {
  return (
    <Container>
      <p>
        It&apos;s not enough to draw general conclusions – not all your users are one and the same.
      </p>
      <p>
        You&apos;ll never be able to please everyone, but that&apos;s alright. You can focus on
        meeting the needs of a specific group instead!
      </p>
      <p>In this article we&apos;ll discuss:</p>
      <ul>
        <li>what exactly is user segmentation</li>
        <li>how can you segmentize your users with different filters</li>
        <li>how segmentation can help you with better user research</li>
        <li>how to use LiveSession for filtering various user groups</li>
      </ul>
      <p>Sounds good? Let&apos;s begin!</p>
      <br />
      <h2>What is user segmentation</h2>
      <p>
        <strong>User segmentation</strong> is a process of creating groups that differ in specific
        characteristics. Each segment has certain traits that distinguish it from other audiences.
      </p>
      <p>
        For example, iPhone users, people who found the website on Google and visitors that clicked
        on a link in a Facebook group are three examples of various segments. There are different
        traits to consider, from browsers and devices to traffic sources and session durations.
      </p>
      <p>
        What&apos;s more, the segment doesn&apos;t always consist of just one characteristic.
        Separate filters can be combined to create a very specific group. Let&apos;s say that
        you&apos;re researching the behavior of your London users when they&apos;re on the go. In
        this case, you might want to consider mobile sessions from a particular city. LiveSession’s
        filtering is quite detailed – and you can add custom filters, too! Have a look at an
        example:
      </p>
      <img
        src={img1}
        alt="user-segmentation"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Example of custom filters"
      />
      <h2>Why user segmentation is important</h2>
      <p>
        Once you start segmenting your users, soon you&apos;ll realize that every group has very{' '}
        <strong>specific needs and problems.</strong> Mobile users deal with other issues than the
        ones who engage with your website on desktop. Different browsers, operating systems and
        devices may display your site in a different way. The list of possibilities is endless, so
        it makes no sense to try guessing – it makes sense to focus on proper research instead.
      </p>
      <h2>Segmentation in user research</h2>
      <p>
        Your audience can be divided into separate groups at the very beginning of your UX workflow.
        LiveSession offers filtering tools to segmentize your user sessions according to a wide
        range of variable to choose from:
      </p>
      <img
        src={img2}
        alt="segmentation-user-research"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Segmentation in user research"
      />
      <h3>Behavioral segmentation</h3>
      <p>
        Let’s start from the basic – the way users interact with your website. When it comes to user
        behavior, you can filter your sessions based on:
      </p>
      <h4>Duration</h4>
      <p>
        Very short visits will help you find reasons for bounce rates. Longer ones are a valuable
        source of information about both the good and the bad things about your website. You can
        choose to see sessions longer than 30 seconds (or even milliseconds!), shorter than two
        minutes, as well as include or exclude specific durations. Here’s how the duration filter
        looks like within the app:
      </p>
      <img
        src={img3}
        alt="research-url"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Duration"
      />
      <h4>Watched</h4>
      <p>
        You don’t need to skip the sessions you’ve already seen manually. Instead, you can filter
        them out in just one click!
      </p>
      <h4>First session</h4>
      <p>
        With LiveSession you can scoop out the first-time visitors only. This way, you can see
        what&apos;s not exactly intuitive at first sight. And vice-versa – you can exclude first
        sessions from the search and focus on returning visitors only.
      </p>
      <h4>Start URL, Exit URL, Visited URL</h4>
      <p>
        These filters can be used to analyze user journeys that included a specific part of your
        website. You may focus on sessions that ended at the checkout, started at the home page or
        included the &apos;About us&apos; section.
      </p>
      <p>Here’s how the filter looks like:</p>
      <img
        src={img4}
        alt="user-research-url"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Session filters"
      />
      <p>
        Let’s start from discussing both drop-down lists in more detail. First of all, you can
        either apply the filters to:
      </p>
      <ol>
        <li>the whole URL</li>
        <li>
          specific parts of the URL – have a look at an example:
          <img
            src={img5}
            alt="user-research-url-1"
            className="img-fluid justify-content-center mb-5 mt-3"
            loading="lazy"
            title="Example of session filters"
          />
          <ul>
            <li>
              <strong>Host</strong> – This is the name of the domain, for example livesession.io.
            </li>
            <li>
              <strong>Path</strong> – A specific part of the website, for example search, order or
              overview.
            </li>
            <li>
              <strong>Query parameter and query value</strong> – You can filter the URLs based on
              different parts on the query, too. These variables may look different depending on the
              structure of your URL.
            </li>
          </ul>
        </li>
      </ol>
      <p>
        When you use the has substring option, you’ll see sessions from pages that contain a
        specific query in the URL. For example, if you’re running a e-commerce store and would like
        to see how your users behave at the checkout, you need to enter a word that appears in your
        checkout’s URL. Likewise, if you’d like to filter product pages only, you need to enter a
        common substring for all of them, e.g. item, product or something similar.
      </p>
      <p>You can also look at one specific URL only, or exclude it from the search:</p>
      <img
        src={img6}
        alt="user-research-url-2"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Exclude specific URL"
      />
      <h4>Click</h4>
      <p>
        As a dedicated click-tracking software, LiveSession allows you to filter your clicks in many
        ways. First of all, you can browse user clicks based on either the CSS selector or text
        only.
      </p>
      <p>
        If you’re not familiar with technical details, this may sound a little daunting at first.
        Don’t worry, though – it can be explained in just a few sentences.
      </p>
      <p>Imagine you’ve got a button like this:</p>
      <img
        src={img7}
        alt="user-research-url-button"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Button"
      />
      <p>
        The developers usually use CSS (Cascading Style Sheets) to implement it on the website. In
        the CSS code, the button looks like this:
      </p>
      <p>
        <strong>{'<button class="big green">Payment</button>'}</strong>
      </p>
      <p>What can you do to find the sessions where the user clicked on that exact button?</p>
      <p>With LiveSession, you can use the text or the CSS selector filter:</p>
      <ul>
        <li>
          <strong>Text</strong> – This filter is tracking the button text. In this case, it’s{' '}
          <strong>“Payment”.</strong>
        </li>
        <li>
          <strong>CSS selector</strong> – To find the button, you need to enter its CSS selector.
          Depending on the precision level, it might be <strong>&apos;button&apos;</strong>,{' '}
          <strong>&apos;button.big&apos;</strong> lub
          <strong>&apos;button.big.red&apos;</strong>.
        </li>
      </ul>
      <p>To see how it works, have a look at an example:</p>
      <img
        src={img8}
        alt="user-research-click"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="CSS selector filter - example"
      />
      <h4>Number of clicks </h4>
      <p>
        With this filter, you can choose to see sessions where the users clicked or tapped something
        on the website. Filtering out sessions without clicks can be helpful if you&apos;d like to
        focus only on the ones where the user has interacted with your website. This can be done by
        selecting a number of clicks greater than 0:
      </p>
      <img
        src={img9}
        alt="user-research-number"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Number of clicks"
      />
      <p>
        You might also choose an exact number or a larger/smaller amount. This will help you find
        sessions where your users were particularly active:
      </p>
      <img
        src={img10}
        alt="user-research-number-2"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Exact number of clicks"
      />
      <p>What’s more, there are two very special types of clicks you can track with LiveSession:</p>
      <ul>
        <li>
          <strong>Error clicks</strong> – This filter will display sessions where a click has
          triggered a JavaScript console error. In other words, you&apos;ll see sessions where
          it&apos;s clear that the user experienced a problem. Here’s how error clicks are presented
          in session replays:
          <img
            src={img11}
            alt="user-research-number-3"
            className="img-fluid justify-content-center mb-5 mt-3"
            loading="lazy"
            title="Example of error click"
          />
        </li>
        <li>
          <strong>Rage clicks</strong> – Used to research user frustration, the rage click filter
          detects people clicking multiple times at the same element. This might happen if a certain
          element, e.g. button or hyperlink, is not working.
        </li>
      </ul>
      <p>
        You might be wondering now, what is the difference between error clicks and rage clicks?
        Both types of clicks often inform you about the same kind of event. Error clicks are
        recorded based on JavaScript errors, while a rage click is detected when someone clicks on
        the same element multiple times in a row.
      </p>
      <h3>Device-based and software-based segmentation</h3>
      <p>
        Using your website on a small smartphone while standing on the subway is a whole different
        experience than browsing it on a 20-inch desktop screen. With this in mind, here&apos;s how
        you can filter your user sessions depending on the device and software:
      </p>
      <h4>Device</h4>
      <p>
        Tablet, smartphone, desktop – what are you going to focus on? Every device has its own
        perks, so it&apos;s particularly important to filter this segment.
      </p>
      <p>
        The filters in this category work in a similar way. Once you hover your mouse over the text
        box, you’ll see a list of the available options. Have a look at an example:
      </p>
      <img
        src={img12}
        alt="based-segmentation"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="System filters"
      />
      <p>
        In this case, the visitors were using the website mainly on desktop. The available options
        depend on the behavior of your users. You’ll only see filters with at least one session
        assigned to them.
      </p>
      <h4>Mobile type</h4>
      <p>
        Why would you need such a specific metric? It might be that there’s an error on the newest
        iPhone model, while the same feature works perfectly fine for the older version. Here’s how
        this filter looks like in action:
      </p>
      <img
        src={img13}
        alt="based-segmentation-2"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="System filters - mobile type"
      />
      <h4>Browser</h4>
      <p>
        Firefox, Safari, Google Chrome, Opera or maybe some niche browser? This information is sure
        to come in handy when trying to solve a bug. What’s more, you can also see if the visitor
        was using the mobile or the desktop version of a browser:
      </p>
      <img
        src={img14}
        alt="based-segmentation-3"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="System filters - browser"
      />
      <h4>OS</h4>
      <p>
        OS X users may have a slightly different view of your website than visitors using Android or
        Windows. As you can see, this functionality covers both mobile and desktop operating
        systems:
      </p>
      <img
        src={img15}
        alt="based-segmentation-4"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="System filters - OS"
      />
      <h4>Screen size, screen width, screen height</h4>
      <p>
        You might think that your web design is perfectly responsible. It doesn&apos;t hurt to
        double-check, though! This filter may help you find fields for improvement.
      </p>
      <p>Hard to imagine how it works? Here’s an example:</p>
      <img
        src={img16}
        alt="based-segmentation-5"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="System filters - screen size"
      />
      <p>
        You can see what are the most common screen resolutions – in this case, 1680x1050 (16:10
        ratio) and 1920x1080 (Full HD). This information is valuable for your designers, as it will
        help them optimize your product accordingly.
      </p>
      <h3>Source-based segmentation</h3>
      <p>
        Your users may behave differently depending on where they came from. There are two main ways
        to track this variable:
      </p>
      <h4>UTM tag</h4>
      <p>
        These are used to track traffic coming from a specific platform. For instance, if you
        advertise your company on Facebook and use UTM tags in your links, you&apos;ll be able to
        see how Facebook audience interacts with your website.
      </p>
      <p>
        With LiveSession, you can browse user sessions according to UTM tags included in the links
        they have clicked:
      </p>
      <img
        src={img17}
        alt="segmentation-search"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Source filters - UTM Campaign"
      />
      <h4>Referrer</h4>
      <p>
        While UTM tags are designed with pay-per-click campaigns in mind, referrers work best when
        you know which domains will be linking to your website. In this case, you’ll see a list of
        websites your users came from. Have a look at an example:
      </p>
      <img
        src={img18}
        alt="segmentation-search-2"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Source filters - Referer"
      />
      <h3>Location-based segmentation</h3>
      <p>
        In many fields, especially e-commerce, the user behavior may vary significantly depending on
        the location. If you&apos;d like to focus on specific market segments, you can filter your
        sessions by:
      </p>
      <h4>Country</h4>
      <p>
        Obviously, this is crucial if your business is operating internationally. The filter
        displays country codes, such as PL (Poland) or AE (United Arab Emirates), like in the
        example below:
      </p>
      <img
        src={img19}
        alt="segmentation-location"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="User filters - country"
      />
      <h4>Region</h4>
      <p>
        This filter is the most helpful for large markets. It&apos;s not enough to differentiate the
        US from other countries – your Florida users may have different habits than visitors from
        Illinois. In case of smaller countries, like Poland, you’re going to see separate
        voivodeships:
      </p>
      <img
        src={img20}
        alt="segmentation-location-2"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="User filters - region"
      />
      <h4>City</h4>
      <p>
        Yes, this is how detailed LiveSession filtering gets! You can even see the city your user
        comes from. It might be really helpful if you’re running a locally-focused campaign.
      </p>
      <img
        src={img21}
        alt="segmentation-location-3"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="User filters - city"
      />
      <h3>Identify your users</h3>
      <p>With LiveSession, you can see exactly who’s who. Here’s how:</p>
      <h4>User ID</h4>
      <p>
        Every visitor can be identified with their unique ID, which is assigned to each of their
        sessions. If you filter all recordings with that ID, you’ll see all the visits of this
        particular user.
      </p>
      <h4>Name, email</h4>
      <p>
        LiveSession allows you to add even more user data. You can identify your users by name and
        email, too. Visit our
        <a
          href="https://livesession.io/help/user-identification-custom-properties/"
          rel="noopener nofollow noreferrer"
          target="_blank"
        >
          help center
        </a>{' '}
        to learn more – we’ve created a tutorial so you can add these properties in just a few
        clicks!
      </p>
      <img
        src={img22}
        alt="identify-users"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="User filters - name"
      />
      <h4>Custom parameters</h4>
      <p>
        Last but not least, you can integrate customer data from sales CRMs, customer service
        software and other tools. This way, it’s much easier to track of all the issues to solve and
        keep the whole team on the same page.
      </p>
      <p>
        Need an example? If you’re selling a SaaS product, the users can be filtered according to
        the product plan they’ve purchased, like this:
      </p>
      <img
        src={img23}
        alt="identify-users-2"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="User filters - plan"
      />
      <h2>Main takeaways</h2>
      <p>
        As you can see, there are many ways to segmentize your user sessions. Last but not least,
        remember that you can save particularly interesting filters as a{' '}
        <strong>pre-built segment:</strong>
      </p>
      <img
        src={img24}
        alt="main-takeaways"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Create segment"
      />
      <p>
        What’s more, the segments can be shared with your team or saved as your private categories.
        Here’s how it works:
      </p>
      <img
        src={img25}
        alt="main-takeaways-2"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Private categories of segmentation"
      />
      <p>
        We encourage you to keep trying various approaches to see what you can discover. Happy
        tracking!
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'User segmentation 101 – how to use filters in user research',
  url: '/blog/how-to-use-filters-in-user-research',
  description: 'LiveSession’s filtering is quite detailed – and you can add custom filters, too!',
  author,
  img,
  imgSocial: img,
  date: '2019-09-12',
  category: '',
  imgStyle: { borderBottom: '1px solid rgba(0,0,0,0.125)' },
  group: 'Articles',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    descriptionSocial="You can't always sit next to your users and see what exactly they're doing on your website. 
    You don't have to, though!"
  />
);
